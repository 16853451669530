<template>
    <div :is="layout">
        <div class="pt-8">
            <v-row>
                <v-col cols="8">
                    <v-card class="userinfo__wrapper">
                        <input type="file" id="upload" ref="upload" @change="changing"
                               accept=".jpg, .jpeg, .png">
                        <v-row v-if="!list.avatar">
                            <v-col cols="12">
                                <div class="userinfo__wrapper__empty" @click="upload" @dragover="dragover"
                                     @drop="dropImage">
                                    <!--                                <p>{{$t('auth.upload_img_hint')}}</p>-->
                                </div>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12">
                                <div @click="upload" class="user_img_preview">
                                    <img :src="list.avatar" alt="">
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                        disabled
                                        v-model="list.username"
                                        :label="$t('auth.username')"
                                        required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                        v-model="list.phone"
                                        :label="$t('auth.phone')"
                                        required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                        v-model="list.email"
                                        :label="$t('auth.email')"
                                        required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                        v-model="list.address"
                                        :label="$t('auth.address')"
                                        required
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="accent" class="ml-4" @click="submit">{{$t('confirm')}}</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="4">
                    <v-card style="height: 588px; background: var(--v-secondary-base);">
                        <v-card-title>
                            账号安全
                        </v-card-title>
                        <v-container style="position:relative; color: #70c542">
                            <div style="height: 100px; width: 100px; position:absolute;  left: 50%; transform: translateX(-50%); margin-top: 100px">
                                <i class="airport icon-lock1" style="font-size: 58px"></i>
                                <div class="profile-main-loader">
                                    <div class="loader">
                                        <svg class="circular-loader" viewBox="25 25 50 50">
                                            <circle class="loader-path" cx="50" cy="50" r="20" fill="none"
                                                    stroke="#70c542" stroke-width="4"/>
                                        </svg>
                                    </div>
                                </div>
                                <div style="">
                                    账号安全
                                </div>
                            </div>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
    import {personal_list, edit_personal} from "../../../api/auth"

    export default {
        data() {
            return {
                user_img: '',
                layout: null,
                list: []
            }
        },
        methods: {
            uploadImg(file_obj) {
                const closeLoading = this.$loading()
                cos.putObject({
                    Bucket: 'cauc-atc-1255710621', /* 必须 */
                    Region: 'ap-nanjing',     /* 存储桶所在地域，必须字段 */
                    Key: `public_picture/user/${this.getTime((new Date()).getTime() / 1000, "yyyy-MM-dd")}/${file_obj.name}`,   /* 必须 */
                    StorageClass: 'STANDARD',
                    Body: file_obj, // 上传文件对象
                }, (err, data) => {
                    this.list.avatar = `https://${data.Location}`
                    closeLoading()
                });
            },
            upload() {
                const uploadbtn = this.$refs.upload
                uploadbtn.click()
            },
            async changing(e) {
                if (e.target.files.length <= 0 || e.target.files.length > 1) {
                    this.$toast({
                        title: this.$t('setting.warning'),
                        message: this.$t('airport.upload_img_once'),
                        type: 'warning'
                    })
                    return
                }
                const file_obj = e.target.files[0]
                this.uploadImg(file_obj)
            },
            dragover(e) {
                e.preventDefault()
            },
            dropImage(e) {
                e.preventDefault()
                if (e.dataTransfer.files.length <= 0 || e.dataTransfer.files.length > 1) {
                    this.$toast({
                        title: this.$t('setting.warning'),
                        message: this.$t('airport.upload_img_once'),
                        type: 'warning'
                    })
                    return
                }
                const file_obj = e.dataTransfer.files[0]
                this.uploadImg(file_obj)
            },
            submit() {
                if (!this.list.avatar) {
                    return this.$toast({
                        'title': '设置错误',
                        'message': '请上传一张头像',
                        'type': 'warning'
                    })
                }
                if (this.list.phone) {
                    if (!/^1[3|5|6|7|8|9]\d{9}$/.test(this.list.phone)) {
                        return this.$toast({
                            title: '设置错误',
                            message: '手机号不正确,请重新输入',
                            type: 'warning'
                        })
                    }
                } else {
                    return this.$toast({
                        title: '设置错误',
                        message: '请输入手机号',
                        type: 'warning'
                    })
                }
                if (!this.list.email) {
                    return this.$toast({
                        title: '设置错误',
                        message: '请输入邮箱',
                        type: 'warning'
                    })
                }
                if (!/^[a-zA-Z0-9]+\w+\w@\w+\.\w+$/.test(this.list.email)) {
                    return this.$toast({
                        title: '设置错误',
                        message: '邮箱有误请重新输入',
                        type: 'warning'
                    })
                }
                edit_personal({
                    avatar: this.list.avatar,
                    phone: this.list.phone,
                    address: this.list.address,
                    email: this.list.email
                }).then(res => {
                    const user_info = JSON.parse(localStorage.getItem('user_info') || '{}')
                    user_info.userinfo.avatar = this.list.avatar
                    localStorage.setItem('user_info', JSON.stringify(user_info))
                    this.$store.commit('set_user_info', user_info)

                    this.$toast({
                        title: this.$t('success'),
                        message: this.$t('auth.edit_user_info')
                    })
                    // this.$router.push({name: 'airportList'})
                })
            },
            reset() {
                this.list = {};
            },

        },
        mounted() {
            this.layout = this.$route.meta.use_layout
            personal_list({id: this.$route.params.id}).then(res => {
                this.list = JSON.parse(JSON.stringify(res.data.userinfo))
                this.list.username = JSON.parse(localStorage.getItem('user_info')).username
            })

        }
    }
</script>
<style lang="stylus" scoped>
    @import '~@/assets/css/global.styl'
    .userinfo__wrapper
        padding: 30px;
        background: var(--v-secondary-base);

        &__empty
            width: 100px;
            height: 100px;
            color: #797979;
            text-align: center;
            background-image: url('https://cauc-atc-1255710621.cos.ap-nanjing.myqcloud.com/assets/avatar.jpeg');
            background-size: 100px 100px;
            border-radius: 50px;

    #upload {
        height: 0;
        width: 0;
        visibility: hidden;
    }

    .user_img_preview
        position relative
        width: 100px;
        height: 100px;
        color: #797979;
        text-align: center;
        overflow hidden
        border-radius 50px;
        border 1px solid var(--v-shadow_color-base)

        img
            width: 100%;
            height: 100%

    .user_img_delete
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0);
        position: absolute;
        top: 0px;
        left: 0px;
        padding-top: 500px;
        transition: ease .2s all

    #wrapper {
        position: relative;
        /*background:#333;*/
        height: 100%;
    }

    .profile-main-loader {
        left: 50% !important;
        position: absolute !important;
        top: 50% !important;
        transform translate(-57%, -40%)
        z-index: 9000 !important;
    }

    .profile-main-loader .loader {
        position: relative;
        margin: 0px auto;
        width: 200px;
        height: 200px;
    }

    .profile-main-loader .loader:before {
        content: '';
        display: block;
        padding-top: 100%;
    }

    .circular-loader {
        -webkit-animation: rotate 2s linear infinite;
        animation: rotate 2s linear infinite;
        height: 100%;
        -webkit-transform-origin: center center;
        -ms-transform-origin: center center;
        transform-origin: center center;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        margin: auto;
    }

    .loader-path {
        stroke-dasharray: 150, 200;
        stroke-dashoffset: -10;
        -webkit-animation: dash 31.5s ease-in-out infinite, color 16s ease-in-out infinite;
        animation: dash 31.5s ease-in-out infinite, color 16s ease-in-out infinite;
        stroke-linecap: round;
    }

    @-webkit-keyframes rotate {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes rotate {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @-webkit-keyframes dash {
        0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124;
        }
    }

    @keyframes dash {
        0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124;
        }
    }

    @-webkit-keyframes color {
        0% {
            stroke: #70c542;
        }
        40% {
            stroke: #70c542;
        }
        66% {
            stroke: #70c542;
        }
        80%, 90% {
            stroke: #70c542;
        }
    }

    @keyframes color {
        0% {
            stroke: #70c542;
        }
        40% {
            stroke: #70c542;
        }
        66% {
            stroke: #70c542;
        }
        80%, 90% {
            stroke: #70c542;
        }
    }


</style>
